import moment from "moment";
import { IDataTime } from "../../../interface";
import { IDevice } from "../../../modules/device/device.interface";

interface IProps {
  lastestValueRealtime?: IDataTime;
  typeValue:
    | "Hourly rainfall"
    | "Water Main Level"
    | "CrackMetter"
    | "Vector Gal_Max"
    | "Rainfall on the day";
  helpCalculateForDemoMonth1112?: any;
  detailDevice?: IDevice;
}
const ExtraValueRealtime = (props: IProps) => {
  //! state
  const {
    lastestValueRealtime,
    typeValue,
    helpCalculateForDemoMonth1112,
    detailDevice,
  } = props;
  //! function
  const checkTypeValue = () => {
    switch (typeValue) {
      case "Hourly rainfall":
        return "Lượng mưa tích luỹ";
      case "Water Main Level":
        return "Mực nước";
      case "CrackMetter":
        return "Căng kế";
      case "Vector Gal_Max":
        return "Rung chấn thời gian thực";
      default:
        return "";
    }
  };

  if (!lastestValueRealtime) {
    return null;
  }
  const valueUsing = () => {
    if (typeValue === "Water Main Level") {
      return helpCalculateForDemoMonth1112(
        detailDevice?.id?.id,
        lastestValueRealtime?.data[`${typeValue}`]?.value
      );
    } else {
      return lastestValueRealtime?.data[`${typeValue}`]?.value;
    }
  };
  //! render
  return (
    <div>
      <div className="flex gap-2 justify-end text-gray-500">
        <span>
          {lastestValueRealtime?.hour}:{lastestValueRealtime?.minute}
        </span>
        <span>
          {moment(lastestValueRealtime?.data.time).format("DD/MM/YYYY")}
        </span>
      </div>

      {
        <div className="font-normal">
          {checkTypeValue()}{" "}
          {typeValue === "Vector Gal_Max" ? ":" : "gần nhất:"}
          {` ${valueUsing()} ${
            lastestValueRealtime?.data[`${typeValue}`]?.unit
          }`}
        </div>
      }
    </div>
  );
};

export default ExtraValueRealtime;

import React, { useEffect, useState } from "react";
import { CommonStyles } from "../../ui";
import CommonImages from "../../../assets/images";
import useGetCustomerDeviceInfos from "../../../modules/device/hooks/useGetCustomerDeviceInfos";
import useToggle from "../../../hooks/useToggle";
import { Marker, Popup } from "react-leaflet";
import { divIcon } from "leaflet";
import CommonIcons from "../../../assets/icons";
import { ResponseGetTelemetry } from "../../../modules/telemetry/telemetry.interface";
import telemetryService from "../../../modules/telemetry/telemetry.service";
import { CDRC, ENTITY_TYPE, LMMN, SCOPE } from "../../../constants/Index";
import { parseQueryString } from "../../../helpers";
import ModalStations from "./ModalStations";
import { useAppSelector } from "../../../hooks/hooks";
import useGetTenantDeviceInfos from "../../../modules/device/hooks/useGetTenantDeviceInfos";
import { IDevice } from "../../../modules/device/device.interface";
import { wsNotification } from "../../../helpers/ws";
import _, { isEmpty } from "lodash";
import { NavLink } from "react-router-dom";
import baseRouter from "../../../router/baseRouter";
interface IMarker {
  id?: string;
  lat?: string;
  lon?: string;
  station?: string;
  address?: string;
  type?: string;
  crackMetter?: any;
  IntensityMax?: any;
  ["Vector Gal_Max"]?: any;
  Temperature?: any;
  ["Water Main Level"]?: any;
  active?: boolean;
  status?: "cleard" | "created";
}
export default function OverallMap() {
  //! state
  const zoom = {
    default: 11,
    minZoom: 10,
    maxZoom: 18,
  };
  const [keyMap, setKeyMap] = useState(0);
  const { open, shouldRender, toggle } = useToggle();
  const {
    open: openFullScreen,
    shouldRender: shouldRenderFullScreen,
    toggle: toggleFullScreen,
  } = useToggle();
  const user = useAppSelector((state) => state.user.user);
  const { data: dataListStation, isLoading: loadingListStation } =
    useGetCustomerDeviceInfos(
      String(user?.customerId.id),
      {
        page: 0,
        pageSize: 100,
      },
      user?.authority !== "TENANT_ADMIN"
    );

  const { data: dataLisStationTenant, isLoading: loadingLisStationTenant } =
    useGetTenantDeviceInfos(
      {
        page: 0,
        pageSize: 100,
      },
      user?.authority === "TENANT_ADMIN"
    );
  const [stationType, setStationType] = useState("");
  const [dataFilterMap, setDataFilterMap] = useState<IMarker[]>([]);
  const [dataMarker, setDataMarker] = useState<IMarker[]>([]);
  const [filtersMap, setFiltersMap] = useState({
    LMMN: true,
    CDRC: true,
    warning: true,
  });
  const [dataNoti, setDataNoti] = useState<any>({});
  //! function
  const hanldeFullMap = () => {
    toggleFullScreen();
    setTimeout(() => {
      setKeyMap(keyMap + 1);
    }, 200);
  };
  const handleShowStations = (key: string) => {
    setStationType(key);
    toggle();
  };

  const changeFiltersMap = (key: string, value: boolean) => {
    setFiltersMap((prev) => ({ ...prev, [key]: value }));
  };

  const devices = React.useCallback(() => {
    let lstDevice: {
      id: string;
      station: string;
      type: string;
      active: boolean;
    }[] = [];
    if (user?.authority === "TENANT_ADMIN") {
      dataLisStationTenant?.data.data?.map((item) => {
        if (item.type === LMMN || item.type === CDRC) {
          lstDevice.push({
            id: item.id.id,
            station: item.name,
            type: item.type,
            active: item.active,
          });
        }
      });
    } else {
      dataListStation?.data.data?.map((item) => {
        lstDevice.push({
          id: item.id.id,
          station: item.name,
          type: item.type,
          active: item.active,
        });
      });
    }

    return lstDevice;
  }, [loadingListStation, loadingLisStationTenant]);

  const receiveNotification = (data: any) => {
    if (!isEmpty(data.update)) {
      setDataNoti(data.update);
    }
  };
  useEffect(() => {
    const notifi = () => {
      wsNotification(
        "plugins/notifications",
        {
          unreadSubCmd: {
            cmdId: 4,
            limit: 6,
          },
        },
        receiveNotification
      );
    };

    notifi();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const newData: any = [];
      await Promise.all(
        devices()?.map(async (device) => {
          const dataTimeseries: ResponseGetTelemetry =
            await telemetryService.getTimeSeries(
              ENTITY_TYPE,
              device.id,
              parseQueryString({ useStrictDataTypes: true })
            );

          const attributes = await telemetryService.getAttributeScope(
            ENTITY_TYPE,
            device.id,
            SCOPE.SHARED_SCOPE,
            parseQueryString({
              keys: "lat,lon,address",
            })
          );
          const itemWithLatKey = attributes.data?.find(
            (item: any) => item?.key === "lat"
          );
          const itemWithLonKey = attributes.data?.find(
            (item: any) => item?.key === "lon"
          );
          const itemWithAddressKey = attributes.data?.find(
            (item: any) => item?.key === "address"
          );
          if (dataTimeseries.data?.data) {
            const item: IMarker = {
              ...device,
              lat: itemWithLatKey?.value,
              lon: itemWithLonKey?.value,
              address: itemWithAddressKey?.value,
              crackMetter:
                dataTimeseries?.data?.data[0]?.value?.CrackMetter ?? "",
              IntensityMax: dataTimeseries.data?.data[0]?.value?.IntensityMax,
              ["Vector Gal_Max"]:
                dataTimeseries.data?.data[0]?.value?.["Vector Gal_Max"],
              Temperature: dataTimeseries.data?.data[0]?.value?.Temperature,
              ["Water Main Level"]:
                dataTimeseries.data?.data[0]?.value?.["Water Main Level"],
              status: "cleard",
            };
            newData.push(item);
          }
        })
      );
      setDataFilterMap(newData);
      setDataMarker(newData);
    };

    fetchData();
  }, [devices().length]);

  useEffect(() => {
    let dataFilters: IMarker[] = [];
    if (filtersMap.CDRC) {
      const filterCDRC = dataFilterMap.filter((item) => item.type === "CDRC");
      if (filterCDRC.length > 0) {
        dataFilters = [...dataFilters, ...filterCDRC];
      }
    }
    if (filtersMap.LMMN) {
      const filterLMMN = dataFilterMap.filter((item) => item.type === "LMMN");
      if (filterLMMN.length > 0) {
        dataFilters = [...dataFilters, ...filterLMMN];
      }
    }
    setDataMarker(dataFilters);
  }, [filtersMap]);

  useEffect(() => {
    if (!isEmpty(dataMarker)) {
      const dataMarkerNew = [...dataMarker];
      const indexNoti = dataMarkerNew?.findIndex(
        (item) => item?.id === dataNoti?.info?.stateEntityId?.id
      );
      if (indexNoti > -1) {
        dataMarkerNew[indexNoti].status = dataNoti?.info?.action;
        setDataMarker(dataMarkerNew);
      }
    }
  }, [dataNoti]);
  //! render
  const renderStation = () => {
    let dataUsing: IDevice[] = [];
    if (user?.authority === "TENANT_ADMIN") {
      dataUsing = dataLisStationTenant?.data.data as IDevice[];
    } else {
      dataUsing = dataListStation?.data.data as IDevice[];
    }
    const numberLMMN = dataUsing?.filter((item) => item.type === "LMMN");
    const numberCDRC = dataUsing?.filter((item) => item.type === "CDRC");

    const count = _.size(
      _.filter(dataMarker, (item) => item.status === "created" && item.active)
    );

    return (
      <div className="flex">
        {count > 0 && (
          <div className="px-2  border-r xs:text-xs sm:text-sm md:text-base">
            <span className="text-red-400 ml-1">Có trạm vượt ngưỡng</span>
          </div>
        )}

        <div
          className="px-2 border-r cursor-pointer xs:text-xs sm:text-sm md:text-base"
          onClick={() => {
            handleShowStations("");
          }}
        >
          Tổng trạm:
          <span className="text-main-color ml-1">
            {numberCDRC?.length + numberLMMN?.length}
          </span>
        </div>

        <div
          className="px-2 border-r cursor-pointer xs:text-xs sm:text-sm md:text-base"
          onClick={() => {
            handleShowStations("LMMN");
          }}
        >
          Trạm lũ quét:
          <span className="text-main-color ml-1">{numberLMMN?.length}</span>
        </div>

        <div
          className="px-2 cursor-pointer xs:text-xs sm:text-sm md:text-base"
          onClick={() => {
            handleShowStations("CDRC");
          }}
        >
          Trạm sạt lở:
          <span className="text-main-color ml-1">{numberCDRC?.length}</span>
        </div>
      </div>
    );
  };
  const renderSelectOptionMap = () => {
    const classDiv =
      "flex items-center gap-2 px-3 py-1 bg-white border rounded-3xl cursor-pointer ";
    return (
      <div className=" flex flex-col gap-2">
        <div
          className={classDiv + `${!filtersMap.LMMN && " opacity-70"}`}
          onClick={() => {
            changeFiltersMap("LMMN", !filtersMap.LMMN);
          }}
        >
          <img src={CommonImages.luongmua} className="w-6 h-6" alt="" />
          <p className="xs:text-xs sm:text-sm md:text-base">Trạm lũ quét</p>
          <div className={!filtersMap.LMMN ? "invisible" : ""}>
            <CommonIcons.Check />
          </div>
        </div>

        <div
          className={classDiv + `${!filtersMap.CDRC && " opacity-70"}`}
          onClick={() => {
            changeFiltersMap("CDRC", !filtersMap.CDRC);
          }}
        >
          <img src={CommonImages.satlo} className="w-6 h-6" alt="" />
          <p className="xs:text-xs sm:text-sm md:text-base">Trạm sạt lở</p>
          <div className={!filtersMap.CDRC ? "invisible" : ""}>
            <CommonIcons.Check />
          </div>
        </div>

        <div
          className={classDiv + `${!filtersMap.warning && " opacity-70"}`}
          onClick={() => {
            changeFiltersMap("warning", !filtersMap.warning);
          }}
        >
          <img src={CommonImages.canhbao} className="w-6 h-6" alt="" />
          <p className="xs:text-xs sm:text-sm md:text-base">Cảnh báo</p>
          <div className={!filtersMap.warning ? "invisible" : ""}>
            <CommonIcons.Check />
          </div>
        </div>
      </div>
    );
  };
  const renderMarkers = React.useCallback(() => {
    return dataMarker?.map((item: IMarker, index: any) => {
      let iconUse = "";
      let status = "Online";
      if (item.active) {
        if (item.Temperature) {
          if (item.status === "created" && filtersMap.warning) {
            iconUse = iconLMMNWarning;
          } else {
            iconUse = iconLMOnline;
          }
        } else {
          if (item.status === "created" && filtersMap.warning) {
            iconUse = iconSLWarning;
          } else {
            iconUse = iconSLOnline;
          }
        }
      } else {
        if (item.Temperature) {
          iconUse = iconLMOffline;
        } else {
          iconUse = iconSLOffline;
        }
      }

      if (!item.active) {
        status = "Offline";
      }
      const cssWarning =
        item.status === "created" && item.active && filtersMap.warning
          ? "css-icon"
          : "";
      const customMarkerIcon = divIcon({
        className: "marker-icon",
        html: `
              <div class='${cssWarning}'>
                ${iconUse}
              </div>
            `,
        iconSize: [16, 16],
        // iconSize: [25, 46],
        iconAnchor: isEmpty(cssWarning) ? [10, 46] : [14, 46],
        // iconSize: [28, 46],
        // iconAnchor: [10, 46],
        popupAnchor: [0, -30],
      });
      return (
        <Marker
          position={[Number(item?.lat), Number(item?.lon)]}
          key={index}
          icon={customMarkerIcon}
        >
          <Popup className="!p-3" closeButton={false}>
            <div className="flex flex-col">
              <CommonStyles.TypographyUI>
                {`Mã trạm: ${item?.station?.split("/")[0] ?? ""}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Tên trạm: ${item?.station?.split("/")[1] ?? ""}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Loại trạm: ${
                  item?.type === LMMN ? "Cảnh báo lũ quét" : "Cảnh báo sạt lở"
                }`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Trạng thái: ${status}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Vị trí: ${item?.address ?? ""}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Vĩ độ: ${item?.lat ?? ""}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Kinh độ: ${item?.lon ?? ""}`}
              </CommonStyles.TypographyUI>
              <NavLink to={`${baseRouter.station}/${item.id}`}>
                Xem chi tiết
              </NavLink>
            </div>
          </Popup>
        </Marker>
      );
    });
  }, [dataMarker, filtersMap]);

  return (
    <>
      <CommonStyles.CardResize
        title="Bản đồ các trạm"
        ImageIcon={CommonImages.map}
        extra={renderStation()}
      >
        <div className={`h-[650px] relative`}>
          <CommonStyles.LeafletUI zoom={zoom} renderMarkers={renderMarkers()} />

          <CommonStyles.TooltipUI title={"Phóng to"} className="bg-white">
            <CommonStyles.ButtonUI
              onClick={hanldeFullMap}
              className="absolute top-5 right-5 z-[500] p-2 bg-white hover:!bg-white "
            >
              <img src={CommonImages.maximize} alt={CommonImages.maximize} />
            </CommonStyles.ButtonUI>
          </CommonStyles.TooltipUI>

          <div className="absolute bottom-4 left-3 z-[500]">
            {renderSelectOptionMap()}
          </div>
        </div>
      </CommonStyles.CardResize>
      {shouldRender && (
        <CommonStyles.ModalUI
          open={open}
          toggle={toggle}
          title="Danh sách các trạm"
          hiddenAction
        >
          <ModalStations keyStation={stationType} />
        </CommonStyles.ModalUI>
      )}

      {shouldRenderFullScreen && (
        <CommonStyles.ModalUI
          open={openFullScreen}
          toggle={toggleFullScreen}
          hiddenAction
          width={"90vw"}
          title="Map"
          className="!z-2000"
        >
          <div
            className="relative h-screen z-2000"
            style={{ height: "85vh" }}
            key={keyMap}
          >
            <CommonStyles.LeafletUI
              zoom={zoom}
              renderMarkers={renderMarkers()}
            />
            <div className="absolute bottom-4 left-3 z-[500]">
              {renderSelectOptionMap()}
            </div>
          </div>
        </CommonStyles.ModalUI>
      )}
    </>
  );
}

const iconLMMNWarning = `<svg width="40" height="51" viewBox="0 0 40 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.3" cx="20" cy="31" r="12" fill="#DD2C00"/>
<circle opacity="0.5" cx="20.3333" cy="30.667" r="19" fill="#DD2C00"/>
<circle cx="19.9999" cy="30.9997" r="4.66667" fill="white"/>
<path d="M31.3993 9.58914C29.7743 4.66289 25.1918 1.35914 19.9781 1.30664C19.1743 1.40414 18.3531 1.43164 17.5706 1.61414C12.6343 2.78289 9.4131 5.83539 8.3206 10.7704C7.2281 15.7054 8.87185 19.8441 12.8568 22.9754C14.9818 24.6416 16.7831 26.5666 18.1618 28.8866C18.3968 29.2791 18.6168 29.6829 18.8768 30.0579C19.4631 30.9029 20.5406 30.9091 21.1268 30.0579C21.4231 29.6316 21.6806 29.1829 21.9356 28.7254C23.2381 26.4229 24.9856 24.5404 27.1243 22.9754C29.1635 21.485 30.6764 19.3854 31.4447 16.9794C32.2131 14.5734 32.1972 11.9855 31.3993 9.58914Z" fill="url(#paint0_linear_1285_7579)"/>
<path d="M13.8461 12.9846L15.3845 12.0615C16.3322 11.4954 17.5138 11.4954 18.4615 12.0615C19.4091 12.6277 20.5907 12.6277 21.5384 12.0615C22.4861 11.4954 23.6676 11.4954 24.6153 12.0615L26.1538 12.9846" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.8461 7.32308L15.3845 8.24616C16.3322 8.81231 17.5138 8.81231 18.4615 8.24616C19.4091 7.68001 20.5907 7.68001 21.5384 8.24616C22.4861 8.81231 23.6676 8.81231 24.6153 8.24616L26.1538 7.32308" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.8461 17.2923L15.3845 16.3692C16.3322 15.8031 17.5138 15.8031 18.4615 16.3692C19.4091 16.9354 20.5907 16.9354 21.5384 16.3692C22.4861 15.8031 23.6676 15.8031 24.6153 16.3692L26.1538 17.2923" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_1285_7579" x1="7.9931" y1="0.804138" x2="27.8681" y2="23.8666" gradientUnits="userSpaceOnUse">
<stop stop-color="#81EAEA"/>
<stop offset="1" stop-color="#2F80ED"/>
</linearGradient>
</defs>
</svg>
`;
const iconLMOnline = `<svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M34.2493 11.986C32.2181 5.82822 26.4899 1.69853 19.9727 1.6329C18.9681 1.75478 17.9415 1.78916 16.9634 2.01728C10.7931 3.47822 6.76649 7.29384 5.40087 13.4626C4.03525 19.6313 6.08993 24.8048 11.0712 28.7188C13.7274 30.8017 15.979 33.2079 17.7024 36.1079C17.9962 36.5985 18.2712 37.1032 18.5962 37.572C19.329 38.6282 20.6759 38.636 21.4087 37.572C21.779 37.0392 22.1009 36.4782 22.4196 35.9063C24.0477 33.0282 26.2321 30.6751 28.9056 28.7188C31.4545 26.8559 33.3456 24.2313 34.306 21.2238C35.2665 18.2164 35.2466 14.9815 34.2493 11.986Z" fill="url(#paint0_linear_106_1185)"/>
<path d="M12.3077 16.2308L14.2308 15.0769C15.4154 14.3692 16.8924 14.3692 18.077 15.0769C19.2616 15.7846 20.7385 15.7846 21.9231 15.0769C23.1077 14.3692 24.5847 14.3692 25.7693 15.0769L27.6924 16.2308" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3077 9.15384L14.2308 10.3077C15.4154 11.0154 16.8924 11.0154 18.077 10.3077C19.2616 9.59999 20.7385 9.59999 21.9231 10.3077C23.1077 11.0154 24.5847 11.0154 25.7693 10.3077L27.6924 9.15384" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3077 21.6154L14.2308 20.4615C15.4154 19.7538 16.8924 19.7538 18.077 20.4615C19.2616 21.1692 20.7385 21.1692 21.9231 20.4615C23.1077 19.7538 24.5847 19.7538 25.7693 20.4615L27.6924 21.6154" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_106_1185" x1="4.99149" y1="1.00478" x2="29.8352" y2="29.8329" gradientUnits="userSpaceOnUse">
<stop stop-color="#81EAEA"/>
<stop offset="1" stop-color="#2F80ED"/>
</linearGradient>
</defs>
</svg>
`;
const iconLMOffline = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.3995 9.58882C25.7745 4.66257 21.192 1.35882 15.9782 1.30632C15.1745 1.40382 14.3532 1.43132 13.5707 1.61382C8.63447 2.78257 5.41322 5.83507 4.32072 10.7701C3.22822 15.7051 4.87197 19.8438 8.85697 22.9751C10.982 24.6413 12.7832 26.5663 14.162 28.8863C14.397 29.2788 14.617 29.6826 14.877 30.0576C15.4632 30.9026 16.5407 30.9088 17.127 30.0576C17.4232 29.6313 17.6807 29.1826 17.9357 28.7251C19.2382 26.4226 20.9857 24.5401 23.1245 22.9751C25.1636 21.4847 26.6765 19.385 27.4449 16.9791C28.2132 14.5731 28.1973 11.9852 27.3995 9.58882Z" fill="url(#paint0_linear_696_14447)"/>
<path d="M9.84613 12.9846L11.3846 12.0615C12.3323 11.4954 13.5138 11.4954 14.4615 12.0615C15.4092 12.6277 16.5907 12.6277 17.5384 12.0615C18.4861 11.4954 19.6677 11.4954 20.6154 12.0615L22.1538 12.9846" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.84613 7.32307L11.3846 8.24615C12.3323 8.81231 13.5138 8.81231 14.4615 8.24615C15.4092 7.68 16.5907 7.68 17.5384 8.24615C18.4861 8.81231 19.6677 8.81231 20.6154 8.24615L22.1538 7.32307" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.84613 17.2923L11.3846 16.3692C12.3323 15.8031 13.5138 15.8031 14.4615 16.3692C15.4092 16.9354 16.5907 16.9354 17.5384 16.3692C18.4861 15.8031 19.6677 15.8031 20.6154 16.3692L22.1538 17.2923" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_696_14447" x1="3.99322" y1="0.803818" x2="23.8682" y2="23.8663" gradientUnits="userSpaceOnUse">
<stop stop-color="#F8FBFF"/>
<stop offset="1" stop-color="#697486"/>
</linearGradient>
</defs>
</svg>
`;

const iconSLWarning = `<svg width="40" height="51" viewBox="0 0 40 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.3" cx="20" cy="31" r="12" fill="#DD2C00"/>
<circle opacity="0.5" cx="20.3333" cy="30.667" r="19" fill="#DD2C00"/>
<circle cx="19.9999" cy="30.9997" r="4.66667" fill="white"/>
<g clip-path="url(#clip0_1289_7580)">
<path d="M31.3993 9.58914C29.7743 4.66289 25.1918 1.35914 19.9781 1.30664C19.1743 1.40414 18.3531 1.43164 17.5706 1.61414C12.6343 2.78289 9.4131 5.83539 8.3206 10.7704C7.2281 15.7054 8.87185 19.8441 12.8568 22.9754C14.9818 24.6416 16.7831 26.5666 18.1618 28.8866C18.3968 29.2791 18.6168 29.6829 18.8768 30.0579C19.4631 30.9029 20.5406 30.9091 21.1268 30.0579C21.4231 29.6316 21.6806 29.1829 21.9356 28.7254C23.2381 26.4229 24.9856 24.5404 27.1243 22.9754C29.1635 21.485 30.6764 19.3854 31.4447 16.9794C32.2131 14.5734 32.1972 11.9855 31.3993 9.58914Z" fill="url(#paint0_linear_1289_7580)"/>
<path d="M31.3993 9.58914C29.7743 4.66289 25.1918 1.35914 19.9781 1.30664C19.1743 1.40414 18.3531 1.43164 17.5706 1.61414C12.6343 2.78289 9.4131 5.83539 8.3206 10.7704C7.2281 15.7054 8.87185 19.8441 12.8568 22.9754C14.9818 24.6416 16.7831 26.5666 18.1618 28.8866C18.3968 29.2791 18.6168 29.6829 18.8768 30.0579C19.4631 30.9029 20.5406 30.9091 21.1268 30.0579C21.4231 29.6316 21.6806 29.1829 21.9356 28.7254C23.2381 26.4229 24.9856 24.5404 27.1243 22.9754C29.1635 21.485 30.6764 19.3854 31.4447 16.9794C32.2131 14.5734 32.1972 11.9855 31.3993 9.58914Z" fill="url(#paint1_linear_1289_7580)"/>
<g clip-path="url(#clip1_1289_7580)">
<path d="M16.4262 10.3536C16.4827 10.3791 16.5416 10.3989 16.602 10.4127C17.2857 10.4929 17.8132 10.85 18.2465 11.3589C18.3387 11.4702 18.3974 11.6053 18.4158 11.7486C18.4478 12.0447 18.3462 12.3998 18.4754 12.6297C18.6046 12.8596 18.9337 13.0074 19.2012 13.1366C19.7021 13.3771 19.9681 13.7517 19.9901 14.3007C20.0126 14.8697 20.2906 15.1778 20.8662 15.2524C21.5138 15.3361 22.0147 15.6762 22.3318 16.2507C22.6138 16.7611 23.02 16.9425 23.5841 16.8192C24.2923 16.665 24.8248 16.8779 25.214 17.5275C25.4644 17.9458 25.8316 18.2934 26.1286 18.6856C26.1988 18.7788 26.2554 18.9511 26.2148 19.0387C26.1742 19.1264 26.0034 19.1935 25.8897 19.194C21.9159 19.2017 17.9411 19.2035 13.9653 19.1995C13.6753 19.1995 13.5991 19.1224 13.5991 18.8228C13.5991 14.7973 13.6008 10.7714 13.6041 6.74515C13.6041 6.63044 13.6677 6.46014 13.7544 6.41656C13.8411 6.37298 14.0134 6.42407 14.105 6.49169C14.6645 6.90043 15.213 7.32419 15.7615 7.74745C15.84 7.8088 15.9077 7.88277 15.9619 7.96634C16.2123 8.35153 16.4628 8.73522 16.6922 9.13344C16.7523 9.25582 16.7662 9.39583 16.7312 9.52765C16.6541 9.80615 16.5349 10.0731 16.4262 10.3536Z" fill="white"/>
<path d="M19.3985 7.10365C20.0878 6.9078 20.4544 7.26394 20.7114 7.84699C20.8181 8.08892 20.9999 8.2978 21.1091 8.53873C21.134 8.60006 21.146 8.66584 21.1445 8.73201C21.1429 8.79818 21.1278 8.86333 21.1001 8.92342C20.8986 9.26868 20.6791 9.60307 20.4424 9.92522C20.3672 10.0213 20.261 10.0883 20.1419 10.1146C19.7171 10.1947 19.2863 10.2468 18.8591 10.3149C18.6752 10.345 18.5585 10.2804 18.4769 10.1146C18.256 9.64822 18.0216 9.1879 17.8142 8.71555C17.7697 8.59524 17.7736 8.46234 17.8252 8.34488C18.0013 7.98594 18.1964 7.63665 18.4098 7.2985C18.4864 7.1978 18.5992 7.13095 18.7243 7.11217C18.9462 7.08312 19.1736 7.10365 19.3985 7.10365Z" fill="white"/>
<path d="M21.5824 11.4014C22.1665 11.2436 22.4891 11.5356 22.707 12.0375C22.7891 12.2264 22.9128 12.3987 23.027 12.573C23.1312 12.7313 23.1372 12.8775 23.021 13.0328C22.9297 13.1414 22.8518 13.2606 22.7891 13.3879C22.5732 13.8989 22.173 14.0892 21.6465 14.0927C21.5129 14.0934 21.38 14.113 21.2518 14.1508C20.9824 14.231 20.8311 14.1298 20.7249 13.8808C20.5841 13.5517 20.4294 13.2297 20.2591 12.9136C20.1494 12.7132 20.1614 12.5434 20.2801 12.3551C20.4191 12.1375 20.5451 11.9119 20.6573 11.6794C20.7575 11.4695 20.9037 11.3813 21.1326 11.3994C21.2814 11.4109 21.4322 11.4014 21.5824 11.4014Z" fill="white"/>
<path d="M25.2094 9.59914C25.2016 9.64861 25.1871 9.6968 25.1664 9.7424C24.972 10.0429 24.7842 10.35 24.5698 10.636C24.5107 10.7146 24.3724 10.7512 24.2637 10.7717C23.9632 10.8288 23.6536 10.8614 23.3506 10.916C23.1447 10.9526 23.019 10.8799 22.9333 10.6926C22.7736 10.342 22.5897 10.0069 22.444 9.65323C22.3967 9.5262 22.398 9.3862 22.4475 9.26003C22.5746 8.97893 22.7254 8.70914 22.8983 8.45358C22.9361 8.40365 22.9836 8.36179 23.0379 8.33048C23.0922 8.29917 23.1522 8.27903 23.2143 8.27125C23.5554 8.25013 23.8975 8.24929 24.2387 8.26875C24.3389 8.27426 24.4776 8.33136 24.5297 8.41C24.7556 8.74961 24.957 9.10575 25.1639 9.45738C25.1842 9.50283 25.1995 9.55037 25.2094 9.59914Z" fill="white"/>
<path d="M24.5493 13.9835C24.942 13.8553 25.1769 14.1112 25.3838 14.4999C25.767 15.2202 25.7845 14.9733 25.3597 15.6826C25.1819 15.9791 24.9495 16.0673 24.6319 16.1083C23.8074 16.215 23.9808 16.3022 23.5936 15.5573C23.4127 15.2067 23.2865 14.9062 23.557 14.5055C23.9562 13.9184 23.7729 13.994 24.5493 13.9835Z" fill="white"/>
<path d="M21.9646 14.6191C22.1725 14.6582 22.4219 14.8976 22.4154 15.0504C22.4099 15.2152 22.3153 15.3114 22.1495 15.3204C21.9957 15.3289 21.7292 15.0795 21.7618 14.9317C21.7883 14.818 21.8945 14.7228 21.9646 14.6191Z" fill="white"/>
<path d="M19.6275 11.5902C19.4041 11.5296 19.1882 11.2927 19.2072 11.1334C19.2263 10.9741 19.3299 10.8829 19.4942 10.889C19.65 10.896 19.906 11.17 19.8589 11.3012C19.8198 11.4114 19.7076 11.495 19.6275 11.5902Z" fill="white"/>
<path d="M24.8868 12.1893C24.7866 12.2564 24.69 12.3606 24.5788 12.3827C24.4205 12.4147 24.1836 12.1638 24.1896 12.0015C24.1956 11.8392 24.2897 11.741 24.456 11.7345C24.6223 11.728 24.8432 11.9634 24.8868 12.1893Z" fill="white"/>
<path d="M16.0234 7.2359C16.1281 7.16878 16.2238 7.0656 16.3385 7.04406C16.4888 7.01551 16.7307 7.2895 16.7202 7.44027C16.7092 7.60707 16.608 7.69623 16.4447 7.69824C16.2814 7.70024 16.06 7.4568 16.0234 7.2359Z" fill="white"/>
<path d="M21.8801 7.07129C22.1065 7.10335 22.3384 7.31623 22.3434 7.48553C22.3484 7.65484 22.2577 7.75051 22.093 7.76604C21.9427 7.78006 21.6697 7.54163 21.6922 7.38886C21.7138 7.27616 21.8155 7.17648 21.8801 7.07129Z" fill="white"/>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_1289_7580" x1="7.9931" y1="0.804138" x2="27.8681" y2="23.8666" gradientUnits="userSpaceOnUse">
<stop stop-color="#81EAEA"/>
<stop offset="1" stop-color="#2F80ED"/>
</linearGradient>
<linearGradient id="paint1_linear_1289_7580" x1="7.9931" y1="0.804138" x2="27.8681" y2="23.8666" gradientUnits="userSpaceOnUse">
<stop stop-color="#EAB981"/>
<stop offset="1" stop-color="#BB431E"/>
</linearGradient>
<clipPath id="clip0_1289_7580">
<rect width="32" height="32" fill="white" transform="translate(4)"/>
</clipPath>
<clipPath id="clip1_1289_7580">
<rect width="12.6302" height="12.8" fill="white" transform="translate(13.6001 6.40039)"/>
</clipPath>
</defs>
</svg>
`;
const iconSLOnline = `<svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M34.2492 11.986C32.2179 5.82822 26.4898 1.69853 19.9726 1.6329C18.9679 1.75478 17.9414 1.78916 16.9632 2.01728C10.7929 3.47822 6.76637 7.29384 5.40075 13.4626C4.03512 19.6313 6.08981 24.8048 11.0711 28.7188C13.7273 30.8017 15.9789 33.2079 17.7023 36.1079C17.9961 36.5985 18.2711 37.1032 18.5961 37.572C19.3289 38.6282 20.6757 38.636 21.4086 37.572C21.7789 37.0392 22.1007 36.4782 22.4195 35.9063C24.0476 33.0282 26.232 30.6751 28.9054 28.7188C31.4543 26.8559 33.3454 24.2313 34.3059 21.2238C35.2664 18.2164 35.2465 14.9815 34.2492 11.986Z" fill="url(#paint0_linear_106_1172)"/>
<g clip-path="url(#clip0_106_1172)">
<path d="M15.5326 12.942C15.6032 12.9739 15.6769 12.9986 15.7524 13.0159C16.6071 13.1161 17.2664 13.5625 17.808 14.1986C17.9233 14.3377 17.9967 14.5066 18.0196 14.6858C18.0597 15.0558 17.9326 15.4997 18.0941 15.7871C18.2556 16.0745 18.667 16.2592 19.0014 16.4208C19.6275 16.7213 19.96 17.1896 19.9875 17.8759C20.0157 18.5872 20.3632 18.9722 21.0826 19.0655C21.8922 19.1701 22.5183 19.5952 22.9146 20.3134C23.2672 20.9514 23.7749 21.1781 24.48 21.024C25.3653 20.8312 26.0309 21.0973 26.5174 21.9094C26.8304 22.4322 27.2894 22.8667 27.6607 23.357C27.7483 23.4734 27.8191 23.6888 27.7684 23.7984C27.7177 23.908 27.5041 23.9919 27.362 23.9925C22.3948 24.0021 17.4262 24.0044 12.4565 23.9994C12.094 23.9994 11.9988 23.903 11.9988 23.5285C11.9988 18.4966 12.0009 13.4642 12.005 8.4314C12.005 8.28802 12.0846 8.07514 12.1929 8.02067C12.3012 7.96619 12.5166 8.03006 12.6312 8.11458C13.3305 8.6255 14.0162 9.1552 14.7018 9.68428C14.7998 9.76097 14.8845 9.85343 14.9522 9.9579C15.2653 10.4394 15.5783 10.919 15.8651 11.4168C15.9403 11.5697 15.9577 11.7448 15.9139 11.9095C15.8175 12.2577 15.6685 12.5914 15.5326 12.942Z" fill="white"/>
<path d="M19.2481 8.87908C20.1097 8.63426 20.568 9.07944 20.8892 9.80825C21.0225 10.1107 21.2498 10.3718 21.3863 10.6729C21.4174 10.7496 21.4325 10.8318 21.4305 10.9145C21.4286 10.9972 21.4097 11.0787 21.3751 11.1538C21.1232 11.5854 20.8488 12.0034 20.553 12.406C20.459 12.5262 20.3262 12.6098 20.1773 12.6427C19.6463 12.7429 19.1079 12.808 18.5738 12.8932C18.344 12.9307 18.1981 12.85 18.096 12.6427C17.8199 12.0598 17.5269 11.4844 17.2677 10.8939C17.212 10.7436 17.217 10.5774 17.2814 10.4306C17.5016 9.98194 17.7455 9.54533 18.0121 9.12264C18.1079 8.99677 18.2489 8.91321 18.4053 8.88972C18.6827 8.85341 18.967 8.87908 19.2481 8.87908Z" fill="white"/>
<path d="M21.9779 14.2512C22.708 14.054 23.1112 14.419 23.3836 15.0464C23.4863 15.2825 23.6409 15.4978 23.7837 15.7157C23.9139 15.9136 23.9214 16.0964 23.7762 16.2905C23.662 16.4263 23.5647 16.5753 23.4863 16.7344C23.2164 17.3731 22.7162 17.611 22.0581 17.6154C21.891 17.6163 21.7249 17.6407 21.5647 17.688C21.2279 17.7882 21.0388 17.6617 20.906 17.3506C20.7301 16.9392 20.5366 16.5366 20.3237 16.1415C20.1866 15.8911 20.2016 15.6788 20.35 15.4434C20.5238 15.1714 20.6812 14.8894 20.8215 14.5987C20.9467 14.3364 21.1295 14.2262 21.4157 14.2487C21.6016 14.2631 21.7901 14.2512 21.9779 14.2512Z" fill="white"/>
<path d="M26.5117 11.9984C26.5019 12.0603 26.4838 12.1205 26.4579 12.1775C26.2149 12.5532 25.9802 12.937 25.7122 13.2945C25.6383 13.3928 25.4655 13.4385 25.3296 13.4642C24.9539 13.5356 24.567 13.5763 24.1882 13.6445C23.9308 13.6902 23.7737 13.5994 23.6666 13.3653C23.4669 12.927 23.2371 12.5081 23.0549 12.0661C22.9959 11.9073 22.9974 11.7323 23.0593 11.5746C23.2182 11.2232 23.4067 10.8859 23.6228 10.5665C23.6701 10.5041 23.7295 10.4518 23.7973 10.4126C23.8652 10.3735 23.9401 10.3483 24.0179 10.3386C24.4442 10.3122 24.8718 10.3111 25.2983 10.3354C25.4235 10.3423 25.597 10.4137 25.6621 10.512C25.9445 10.9365 26.1962 11.3817 26.4548 11.8212C26.4801 11.8781 26.4993 11.9375 26.5117 11.9984Z" fill="white"/>
<path d="M25.6864 17.4789C26.1773 17.3186 26.4709 17.6386 26.7295 18.1244C27.2085 19.0248 27.2304 18.7161 26.6995 19.6027C26.4772 19.9734 26.1867 20.0836 25.7897 20.1349C24.7591 20.2683 24.9757 20.3772 24.4917 19.4462C24.2657 19.0079 24.1079 18.6322 24.446 18.1313C24.945 17.3975 24.7159 17.4921 25.6864 17.4789Z" fill="white"/>
<path d="M22.4557 18.2728C22.7156 18.3217 23.0274 18.621 23.0192 18.8119C23.0123 19.0179 22.894 19.1381 22.6868 19.1494C22.4945 19.16 22.1614 18.8482 22.2021 18.6635C22.2353 18.5214 22.3681 18.4024 22.4557 18.2728Z" fill="white"/>
<path d="M19.5341 14.4873C19.2549 14.4115 18.985 14.1154 19.0088 13.9162C19.0326 13.7171 19.1622 13.6032 19.3676 13.6107C19.5623 13.6195 19.8822 13.962 19.8234 14.126C19.7745 14.2637 19.6343 14.3683 19.5341 14.4873Z" fill="white"/>
<path d="M26.1085 15.2367C25.9833 15.3206 25.8625 15.4509 25.7235 15.4784C25.5256 15.5185 25.2294 15.2048 25.237 15.002C25.2445 14.7991 25.3622 14.6764 25.5701 14.6682C25.7779 14.6601 26.054 14.9544 26.1085 15.2367Z" fill="white"/>
<path d="M15.0293 9.045C15.1602 8.9611 15.2797 8.83212 15.4231 8.8052C15.611 8.76951 15.9134 9.112 15.9002 9.30046C15.8865 9.50896 15.76 9.62041 15.5559 9.62292C15.3518 9.62542 15.075 9.32112 15.0293 9.045Z" fill="white"/>
<path d="M22.35 8.839C22.633 8.87908 22.9229 9.14518 22.9291 9.35681C22.9354 9.56844 22.8221 9.68803 22.6161 9.70744C22.4282 9.72497 22.087 9.42694 22.1152 9.23597C22.1421 9.09509 22.2692 8.97049 22.35 8.839Z" fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear_106_1172" x1="4.99137" y1="1.00478" x2="29.8351" y2="29.8329" gradientUnits="userSpaceOnUse">
<stop stop-color="#EAB981"/>
<stop offset="1" stop-color="#BB431E"/>
</linearGradient>
<clipPath id="clip0_106_1172">
<rect width="15.7877" height="16" fill="white" transform="translate(12 8)"/>
</clipPath>
</defs>
</svg>
`;

const iconSLOffline = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.3994 9.58882C25.7744 4.66258 21.1919 1.35882 15.9782 1.30632C15.1744 1.40382 14.3532 1.43133 13.5707 1.61383C8.63444 2.78258 5.41319 5.83508 4.32069 10.7701C3.22819 15.7051 4.87194 19.8438 8.85694 22.9751C10.9819 24.6413 12.7832 26.5663 14.1619 28.8863C14.3969 29.2788 14.6169 29.6826 14.8769 30.0576C15.4632 30.9026 16.5407 30.9088 17.1269 30.0576C17.4232 29.6313 17.6807 29.1826 17.9357 28.7251C19.2382 26.4226 20.9857 24.5401 23.1244 22.9751C25.1636 21.4847 26.6765 19.3851 27.4448 16.9791C28.2132 14.5731 28.1973 11.9852 27.3994 9.58882Z" fill="url(#paint0_linear_696_14425)"/>
<g clip-path="url(#clip0_696_14425)">
<path d="M12.4261 10.3536C12.4826 10.3791 12.5415 10.3989 12.6019 10.4127C13.2856 10.4929 13.8131 10.85 14.2463 11.3589C14.3386 11.4701 14.3973 11.6053 14.4157 11.7486C14.4477 12.0446 14.346 12.3998 14.4753 12.6297C14.6045 12.8596 14.9336 13.0074 15.2011 13.1366C15.702 13.377 15.9679 13.7517 15.99 14.3007C16.0125 14.8697 16.2905 15.1778 16.8661 15.2524C17.5137 15.3361 18.0146 15.6762 18.3317 16.2507C18.6137 16.7611 19.0199 16.9424 19.5839 16.8192C20.2922 16.6649 20.8247 16.8778 21.2139 17.5275C21.4643 17.9457 21.8315 18.2934 22.1285 18.6856C22.1986 18.7787 22.2552 18.9511 22.2147 19.0387C22.1741 19.1264 22.0033 19.1935 21.8896 19.194C17.9158 19.2017 13.941 19.2035 9.96516 19.1995C9.67514 19.1995 9.599 19.1224 9.599 18.8228C9.599 14.7973 9.60067 10.7714 9.60401 6.74512C9.60401 6.63041 9.66762 6.46011 9.75428 6.41653C9.84093 6.37295 10.0132 6.42404 10.1049 6.49166C10.6644 6.9004 11.2129 7.32416 11.7614 7.74742C11.8398 7.80877 11.9076 7.88274 11.9617 7.96631C12.2122 8.3515 12.4626 8.73519 12.6921 9.13341C12.7522 9.25579 12.7661 9.39581 12.7311 9.52762C12.654 9.80612 12.5348 10.0731 12.4261 10.3536Z" fill="white"/>
<path d="M15.3984 7.10326C16.0877 6.90741 16.4543 7.26355 16.7113 7.8466C16.818 8.08853 16.9998 8.29741 17.109 8.53834C17.1339 8.59967 17.1459 8.66545 17.1444 8.73162C17.1428 8.79779 17.1277 8.86294 17.1 8.92303C16.8985 9.26829 16.679 9.60268 16.4423 9.92483C16.3671 10.0209 16.2609 10.0879 16.1417 10.1142C15.717 10.1943 15.2862 10.2464 14.8589 10.3145C14.6751 10.3446 14.5584 10.28 14.4768 10.1142C14.2559 9.64784 14.0214 9.18751 13.8141 8.71516C13.7696 8.59485 13.7735 8.46195 13.8251 8.34449C14.0012 7.98556 14.1963 7.63627 14.4096 7.29811C14.4862 7.19742 14.5991 7.13057 14.7242 7.11178C14.9461 7.08273 15.1735 7.10326 15.3984 7.10326Z" fill="white"/>
<path d="M17.5823 11.401C18.1664 11.2432 18.489 11.5352 18.7069 12.0371C18.789 12.226 18.9127 12.3983 19.0269 12.5726C19.1311 12.7309 19.1371 12.8771 19.0209 13.0324C18.9296 13.141 18.8517 13.2603 18.789 13.3876C18.5731 13.8985 18.1729 14.0888 17.6465 14.0923C17.5128 14.093 17.3799 14.1126 17.2517 14.1504C16.9823 14.2306 16.831 14.1294 16.7248 13.8804C16.584 13.5514 16.4293 13.2293 16.259 12.9132C16.1493 12.7128 16.1613 12.543 16.28 12.3547C16.419 12.1371 16.545 11.9115 16.6572 11.679C16.7574 11.4691 16.9036 11.381 17.1325 11.399C17.2813 11.4105 17.4321 11.401 17.5823 11.401Z" fill="white"/>
<path d="M21.2094 9.59875C21.2015 9.64822 21.187 9.69641 21.1663 9.74201C20.9719 10.0425 20.7841 10.3496 20.5697 10.6356C20.5106 10.7143 20.3724 10.7508 20.2637 10.7714C19.9631 10.8285 19.6536 10.861 19.3505 10.9156C19.1447 10.9522 19.0189 10.8795 18.9333 10.6922C18.7735 10.3416 18.5897 10.0065 18.4439 9.65285C18.3967 9.52581 18.3979 9.38582 18.4474 9.25964C18.5746 8.97854 18.7254 8.70875 18.8982 8.45319C18.9361 8.40326 18.9835 8.3614 19.0378 8.33009C19.0921 8.29878 19.1521 8.27864 19.2143 8.27086C19.5554 8.24974 19.8974 8.2489 20.2386 8.26836C20.3388 8.27387 20.4776 8.33097 20.5296 8.40961C20.7556 8.74922 20.9569 9.10536 21.1638 9.45699C21.1841 9.50244 21.1994 9.54998 21.2094 9.59875Z" fill="white"/>
<path d="M20.5492 13.9831C20.9419 13.8549 21.1768 14.1109 21.3837 14.4996C21.7669 15.2198 21.7844 14.9729 21.3596 15.6822C21.1818 15.9787 20.9494 16.0669 20.6318 16.1079C19.8074 16.2146 19.9807 16.3018 19.5935 15.557C19.4126 15.2063 19.2864 14.9058 19.5569 14.5051C19.9561 13.918 19.7728 13.9936 20.5492 13.9831Z" fill="white"/>
<path d="M17.9645 14.6183C18.1724 14.6573 18.4219 14.8968 18.4153 15.0495C18.4098 15.2143 18.3152 15.3105 18.1494 15.3195C17.9956 15.328 17.7291 15.0786 17.7617 14.9308C17.7882 14.8171 17.8944 14.722 17.9645 14.6183Z" fill="white"/>
<path d="M15.6273 11.5898C15.4039 11.5292 15.188 11.2923 15.2071 11.133C15.2261 10.9737 15.3298 10.8826 15.4941 10.8886C15.6499 10.8956 15.9058 11.1696 15.8587 11.3008C15.8197 11.411 15.7075 11.4947 15.6273 11.5898Z" fill="white"/>
<path d="M20.8868 12.1894C20.7866 12.2565 20.6899 12.3607 20.5787 12.3827C20.4204 12.4148 20.1835 12.1639 20.1895 12.0016C20.1955 11.8393 20.2897 11.7411 20.456 11.7346C20.6223 11.7281 20.8432 11.9635 20.8868 12.1894Z" fill="white"/>
<path d="M12.0233 7.236C12.128 7.16888 12.2237 7.0657 12.3384 7.04416C12.4887 7.01561 12.7306 7.2896 12.7201 7.44037C12.7091 7.60717 12.6079 7.69633 12.4446 7.69833C12.2813 7.70034 12.0599 7.4569 12.0233 7.236Z" fill="white"/>
<path d="M17.8799 7.07121C18.1063 7.10326 18.3382 7.31615 18.3432 7.48545C18.3482 7.65475 18.2576 7.75043 18.0928 7.76595C17.9425 7.77998 17.6695 7.54155 17.692 7.38878C17.7136 7.27607 17.8153 7.17639 17.8799 7.07121Z" fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear_696_14425" x1="3.99319" y1="0.803822" x2="23.8682" y2="23.8663" gradientUnits="userSpaceOnUse">
<stop stop-color="#F8FBFF"/>
<stop offset="1" stop-color="#697486"/>
</linearGradient>
<clipPath id="clip0_696_14425">
<rect width="12.6302" height="12.8" fill="white" transform="translate(9.60001 6.4)"/>
</clipPath>
</defs>
</svg>
`;

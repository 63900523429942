import { useCallback, useEffect, useState } from "react";
import { getTimeStampHour, parseQueryString } from "../../../../helpers";
import { IDateFilter } from "../../../../interface/IDateFilter";
import { IDevice } from "../../../../modules/device/device.interface";
import { CommonStyles } from "../../../ui";
import {
  IDataTime,
  getDataFilterDate,
  valueIncreaseByFilterDate,
} from "../../../../helpers/filterDate";
import { useGetLabelsChart } from "../../../../hooks/useGetLabelChart";
import _ from "lodash";
import { ENTITY_TYPE } from "../../../../constants/Index";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import { IDataChart } from "../../../../interface";
import RealChartCrackMetter from "./RealChartCrackMetter";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { colorHighLow } from "../../../../constants/color";
import { segment } from "../../../../helpers/chart";
import moment from "moment";
import { BellFilled } from "@ant-design/icons";
import useToggle from "../../../../hooks/useToggle";

interface IProps {
  detailDevice: IDevice;
  dateFilter: IDateFilter;
}
export default function ChartCrackMetter(props: IProps) {
  //! state
  const { detailDevice, dateFilter } = props;
  const [loading, setLoading] = useState(false);
  const [dataChart, setDataChart] = useState<IDataChart>();
  const { data: dataCrack } = useGetAttributeKey(
    ENTITY_TYPE,
    detailDevice.id.id,
    true,
    parseQueryString({
      keys: "crackMetterStart,colorChart,crackMetterStartSoftware,crackMetterThresholdHigh",
    })
  );
  const { open, shouldRender, toggle } = useToggle();

  //! function
  useEffect(() => {
    const dataDevice = async () => {
      const increaseTime = valueIncreaseByFilterDate(dateFilter);
      setLoading(true);
      const dataLow_High = telemetryService.getAttributeKey(
        detailDevice?.id?.entityType,
        detailDevice?.id?.id,
        parseQueryString({ keys: "Sethigh,Setlow" })
      );
      // const dataStart = await telemetryService.getTimeSeries(
      //   detailDevice?.id?.entityType,
      //   detailDevice?.id?.id,
      //   parseQueryString({
      //     startTs: dateFilter.startDate,
      //     endTs: dateFilter.endDate,
      //     limit: 1,
      //     useStrictDataTypes: true,
      //     keys: "data",
      //     orderBy: "ASC",
      //   })
      // );
      let startTime = dateFilter.startDate;
      let dataElement: IDataTime[] = [];

      let check = true;
      if (dateFilter.type === "second") {
        const detailStation = await telemetryService.getTimeSeries(
          detailDevice?.id?.entityType,
          detailDevice?.id?.id,
          parseQueryString({
            startTs: dateFilter.startDate,
            endTs: dateFilter.endDate,
            limit: 50000,
            useStrictDataTypes: true,
            keys: "data",
            orderBy: "DESC",
          })
        );
        if (detailStation?.data?.data) {
          detailStation?.data?.data.map((item: any, index: any) => {
            const dataOrder = getDataFilterDate(
              moment(item.ts).valueOf(),
              item.value
            );
            dataElement.push(dataOrder);
          });
        }
      } else {
        while (startTime <= dateFilter.endDate && check) {
          if (startTime > dateFilter.endDate) {
            startTime = dateFilter.endDate;
            check = false;
          }
          const start = getTimeStampHour(startTime, "start", dateFilter);
          const end = getTimeStampHour(startTime, "end", dateFilter);
          const detailStation = await telemetryService.getTimeSeries(
            detailDevice?.id?.entityType,
            detailDevice?.id?.id,
            parseQueryString({
              startTs: start,
              endTs: end,
              limit: 1,
              useStrictDataTypes: true,
              keys: "data",
              orderBy: "DESC",
            })
          );
          if (detailStation?.data?.data) {
            const dataOrder = getDataFilterDate(
              start,
              detailStation?.data?.data[0]?.value
            );
            dataElement.push(dataOrder);
          }

          startTime += increaseTime;
        }
      }

      setDataChart({
        station: detailDevice.name,
        dataChart: dataElement,
        dataLow_High: (await dataLow_High).data,
      });
      setLoading(false);
    };

    if (detailDevice && dateFilter.value != "real") {
      dataDevice();
    }
  }, [detailDevice, dateFilter]);
  const { labelChart } = useGetLabelsChart(
    dataChart?.dataChart,
    dateFilter,
    loading,
    dataChart?.dataChart && dataChart?.dataChart.length > 0
  );
  const keyFilter = "CrackMetter";

  const generateDataChart = useCallback(() => {
    if (!!dataChart?.dataChart && dataChart.dataChart.length > 0) {
      const dataLine = dataChart?.dataChart.map((child) => {
        return child.data[keyFilter]?.status
          ? child.data[keyFilter]?.value
          : NaN;
      });
      return dataLine;
    } else {
      return [{}];
    }
  }, [dataChart, loading, dateFilter]);

  const handleShowDataAlarm = () => {
    toggle();
  };
  //! render
  const renderBoxReport = (data: IDataTime[], keyFilter: string) => {
    const dataChart = data?.filter((item) => item.data[keyFilter].status !== 0);
    const highCrackMetterSoftware = dataCrack?.data?.find(
      (item) => item?.key === "crackMetterThresholdHigh"
    );
    const valueMaxCrackMetter = Number(
      _.maxBy(dataChart, `data[${keyFilter}].value`)?.data[keyFilter]?.value
    );
    return (
      <CommonStyles.CardResize
        title={"Thống kê căng kế"}
        className=" border border-gray-200 pb-0"
      >
        <div className="grid grid-cols-2 gap-4 pt-5">
          <CommonStyles.BoxInfo
            title={<>Độ dài dây căng lớn nhất</>}
            value={valueMaxCrackMetter}
            unit={"cm"}
            actionNode={
              <div
                onClick={() => {
                  if (valueMaxCrackMetter > highCrackMetterSoftware?.value) {
                    handleShowDataAlarm();
                  }
                }}
                className={` ${
                  valueMaxCrackMetter > highCrackMetterSoftware?.value
                    ? " bell-animation cursor-pointer"
                    : ""
                }`}
              >
                <BellFilled />
              </div>
            }
          />
          <CommonStyles.BoxInfo
            title={<>Độ dài dây căng ban đầu</>}
            value={Number(
              dataCrack?.data?.find(
                (item) => item.key === "crackMetterStartSoftware"
              )?.value
            )}
            unit={"cm"}
          />
        </div>
        {shouldRender && (
          <CommonStyles.ModalUI
            title="Thông tin cảnh báo vượt ngưỡng"
            open={open}
            toggle={toggle}
            hiddenAction
            maskClosable
          >
            <div className="text-lg mt-5">{`Độ dài dây căng: ${valueMaxCrackMetter}cm > ${
              highCrackMetterSoftware?.value
            }cm giá trị ngưỡng tại thời điểm ${moment().format(
              "DD/MM/YYY HH:mm"
            )}`}</div>
          </CommonStyles.ModalUI>
        )}
      </CommonStyles.CardResize>
    );
  };
  const renderChart = (title: string) => {
    if (dateFilter.value === "real") {
      return (
        <RealChartCrackMetter
          loading={loading}
          dateFilter={dateFilter}
          detailDevice={detailDevice}
          title={title}
          renderBoxReport={renderBoxReport}
        />
      );
    } else {
      return (
        <div className="flex flex-col gap-4">
          <CommonStyles.CardResize
            title={title}
            className=" border border-gray-200"
          >
            <CommonStyles.LineChart
              labels={labelChart ?? []}
              dataChart={[
                {
                  borderColor: dataCrack?.data?.find(
                    (el) => el.key === "colorChart"
                  )?.value,
                  data: generateDataChart(),
                  cubicInterpolationMode: "monotone",
                  lineTension: 3,
                  borderWidth: 2.5,
                  segment: segment,
                  spanGaps: true,
                },
                // {
                //   label: `Ngưỡng cao ${dataChart?.station?.split("/")[1]}`,
                //   data: Array.from(
                //     { length: generateDataChart()?.length },
                //     () =>
                //       Number(
                //         dataChart?.dataLow_High &&
                //           dataChart?.dataLow_High
                //             .find((ele) => ele.key === "Sethigh")
                //             ?.value?.split(",")[0]
                //       )
                //   ),
                //   borderColor: colorHighLow.value,
                //   lineTension: 0,
                //   borderWidth: 1.5,
                //   borderDash: [5, 5],
                //   order: 0,
                // },
                // {
                //   label: `Ngưỡng thấp ${dataChart?.station?.split("/")[1]}`,
                //   data: Array.from(
                //     { length: generateDataChart()?.length },
                //     () =>
                //       Number(
                //         dataChart?.dataLow_High &&
                //           dataChart?.dataLow_High
                //             .find((ele) => ele.key === "Setlow")
                //             ?.value?.split(",")[0]
                //       )
                //   ),
                //   borderColor: colorHighLow.value,
                //   lineTension: 0,
                //   borderWidth: 1.5,
                //   borderDash: [5, 5],
                //   order: 0,
                // },
              ]}
              displayLengend={false}
            />
          </CommonStyles.CardResize>

          {renderBoxReport(dataChart?.dataChart as IDataTime[], keyFilter)}
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {loading ? (
        <div className="h-[400px]">
          <CommonStyles.LoadingUI />
        </div>
      ) : (
        renderChart("Căng kế (cm)")
      )}
    </div>
  );
}
